<template>
	<HeroSection />

	<HomeNewsSection />

	<Row class="bg-white">
		<Container>
			<Heading
				tag="h2"
				type="big"
				class="border-l-[8px] pl-[16px] border-primary-500 leading-none mb-[16px]"
				text="Most traders lose money."
			/>

			<Paragraph class="mb-[44px] lg:mb-[64px]">
				That's because they trade without a plan.
			</Paragraph>

			<div
				class="grid lg:grid-cols-3 gap-[36px] lg:gap-[64px] lg:gap-5 justify-center"
			>
				<div
					class="flex flex-col items-center px-[20px] gap-6 lg:px-[40px] trading-benefits"
				>
					<img
						src="/images/icons/traders-loose-1.svg"
						width="194"
						height="137"
						alt="traders-loose-1"
					/>
					<Paragraph class="text-center">
						They don't know how to pick the right entries and exits.
					</Paragraph>
				</div>
				<div
					class="flex flex-col items-center px-[20px] gap-6 lg:px-[40px] trading-benefits"
				>
					<img
						src="/images/icons/traders-loose-2.svg"
						width="199"
						height="136"
						alt="traders-loose-2"
					/>
					<Paragraph class="text-center">
						They don't know which stocks to trade.
					</Paragraph>
				</div>
				<div
					class="flex flex-col items-center px-[20px] gap-6 lg:px-[20px] trading-benefits"
				>
					<img
						src="/images/icons/traders-loose-3.svg"
						width="184"
						height="137"
						alt="traders-loose-3"
					/>
					<Paragraph class="text-center">
						They don't know how to avoid going head to head with Wall Street.
					</Paragraph>
				</div>
			</div>
		</Container>
	</Row>
	<Row>
		<Container>
			<div class="max-w-[820px] mb-4">
				<Heading
					tag="h2"
					type="big"
					class="border-l-[8px] pl-[16px] border-primary-500 leading-none mb-[16px]"
					text="I'm Tim Sykes, a penny stock trader with $7.9 million in documented earnings over 20-plus years."
				/>
			</div>

			<div class="max-w-[600px]">
				<Paragraph class="mb-4">
					My Trading Challenge has seen over 30 students become millionaire traders,
					and countless others find community and expert guidance.
				</Paragraph>
				<CtaButton
					:blank="true"
					to="https://mil.pub/tfnew"
					class=""
					dataCtaType="free-webinar"
				>
					Watch my FREE Webinar
				</CtaButton>
			</div>

			<div class="home-collage">
				<div class="home-collage-1">
					<img
						src="/images/image-17.webp"
						loading="lazy"
						width="590"
						height="342"
						alt="Webinar Montage Image 1"
					/>
					<img
						src="/images/image-16.webp"
						width="469"
						height="352"
						loading="lazy"
						alt="Webinar Montage Image 2"
					/>
				</div>

				<div class="grid lg:grid-cols-2 gap-[32px] xl:pl-[44px]">
					<div class="ts-feature-block">
						<img
							src="/images/icons/Ledger.svg"
							alt="Ledger image"
							width="80"
							height="80"
						/>
						<Heading
							tag="h4"
							text="9,000+ Video Lessons"
							class="mb-0 font-semibold"
						/>
						<Paragraph class="text-center">
							They don't know which stocks to trade.
						</Paragraph>
					</div>
					<div class="ts-feature-block">
						<img
							src="/images/icons/Profit.svg"
							width="80"
							alt="Profit image"
							height="80"
						/>
						<Heading
							tag="h4"
							text="Daily Watchlist"
							class="mb-0 font-semibold"
						/>
						<Paragraph class="text-center">
							My Trading Challenge students receive my exclusive daily watchlist.
						</Paragraph>
					</div>
					<div class="ts-feature-block">
						<img
							src="/images/icons/Video-Chat.svg"
							alt="Video Chat image"
							width="80"
							height="80"
						/>
						<Heading
							tag="h4"
							text="Challenge Chat Room"
							class="mb-0 font-semibold"
						/>
						<Paragraph class="text-center">
							We've got the best chat room on the planet!
						</Paragraph>
					</div>
					<div class="ts-feature-block">
						<img
							src="/images/icons/Ledger.svg"
							alt="Ledger 2 image"
							width="80"
							height="80"
						/>
						<Heading
							tag="h4"
							text="Interactive Webinars"
							class="mb-0 font-semibold"
						/>
						<Paragraph class="text-center">
							Get personal guidance from me and several of my top students.
						</Paragraph>
					</div>
				</div>
			</div>
		</Container>
	</Row>
	<Row class="case-study-section">
		<Container>
			<div class="max-w-[600px] mx-auto mb-4">
				<Heading
					tag="h2"
					type="big"
					class="text-white text-center"
					text="How My Top Students Became Millionaires"
				/>
			</div>

			<div class="grid lg:grid-cols-2 gap-x-[16px] gap-[16px] lg:gap-y-[40px]">
				<div class="student-case-study">
					<img
						src="/images/mariana2.webp"
						width="124"
						height="136"
						loading="lazy"
						alt="Mariana Case Study Pic"
					/>

					<div class="flex flex-col gap-5">
						<Paragraph>
							<b>Mariana Hincapie.</b>
							She became my youngest millionaire student at the age of 20!
						</Paragraph>

						<NuxtLink
							to="/blog/case-study-mariana/"
							class="case-study-link"
						>
							<span class="case-study-link-icon">
								<svg
									width="20"
									height="19"
									viewBox="0 0 20 19"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.5122 1.69513C4.5444 1.69513 3.7561 2.48342 3.7561 3.45123V15.5488C3.7561 16.5166 4.5444 17.3049 5.5122 17.3049H14.4878C15.4556 17.3049 16.2439 16.5166 16.2439 15.5488V7.15854H12.5366C11.5688 7.15854 10.7805 6.37025 10.7805 5.40245V1.69513H5.5122ZM11.9512 1.73019V5.40245C11.9512 5.72635 12.2127 5.98781 12.5366 5.98781H16.2088C16.1815 5.90586 16.1349 5.8315 16.0724 5.76906L12.17 1.86662C12.1075 1.80418 12.0332 1.75751 11.9512 1.73019ZM7.46342 9.10976H12.5366C12.8605 9.10976 13.122 9.37123 13.122 9.69513C13.122 10.019 12.8605 10.2805 12.5366 10.2805H7.46342C7.13952 10.2805 6.87805 10.019 6.87805 9.69513C6.87805 9.37123 7.13952 9.10976 7.46342 9.10976ZM7.46342 11.4512H12.5366C12.8605 11.4512 13.122 11.7127 13.122 12.0366C13.122 12.3605 12.8605 12.622 12.5366 12.622H7.46342C7.13952 12.622 6.87805 12.3605 6.87805 12.0366C6.87805 11.7127 7.13952 11.4512 7.46342 11.4512ZM7.46342 13.7927H10.9756C11.2995 13.7927 11.561 14.0542 11.561 14.3781C11.561 14.702 11.2995 14.9634 10.9756 14.9634H7.46342C7.13952 14.9634 6.87805 14.702 6.87805 14.3781C6.87805 14.0542 7.13952 13.7927 7.46342 13.7927Z"
										fill="white"
									/>
								</svg>
							</span>

							Read Mariana's story here
						</NuxtLink>
					</div>
				</div>
				<div class="student-case-study">
					<img
						src="/images/kellog2.webp"
						width="124"
						height="136"
						loading="lazy"
						alt="Kellog Study Pic"
					/>

					<div class="flex flex-col gap-5">
						<Paragraph>
							<b>Jack Kellogg.</b>
							Jack was working as a valet in 2017 - now he's up more than $12 million!
						</Paragraph>

						<NuxtLink
							to="/blog/jack-kelloggs-journey/"
							class="case-study-link"
						>
							<span class="case-study-link-icon">
								<svg
									width="20"
									height="19"
									viewBox="0 0 20 19"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.5122 1.69513C4.5444 1.69513 3.7561 2.48342 3.7561 3.45123V15.5488C3.7561 16.5166 4.5444 17.3049 5.5122 17.3049H14.4878C15.4556 17.3049 16.2439 16.5166 16.2439 15.5488V7.15854H12.5366C11.5688 7.15854 10.7805 6.37025 10.7805 5.40245V1.69513H5.5122ZM11.9512 1.73019V5.40245C11.9512 5.72635 12.2127 5.98781 12.5366 5.98781H16.2088C16.1815 5.90586 16.1349 5.8315 16.0724 5.76906L12.17 1.86662C12.1075 1.80418 12.0332 1.75751 11.9512 1.73019ZM7.46342 9.10976H12.5366C12.8605 9.10976 13.122 9.37123 13.122 9.69513C13.122 10.019 12.8605 10.2805 12.5366 10.2805H7.46342C7.13952 10.2805 6.87805 10.019 6.87805 9.69513C6.87805 9.37123 7.13952 9.10976 7.46342 9.10976ZM7.46342 11.4512H12.5366C12.8605 11.4512 13.122 11.7127 13.122 12.0366C13.122 12.3605 12.8605 12.622 12.5366 12.622H7.46342C7.13952 12.622 6.87805 12.3605 6.87805 12.0366C6.87805 11.7127 7.13952 11.4512 7.46342 11.4512ZM7.46342 13.7927H10.9756C11.2995 13.7927 11.561 14.0542 11.561 14.3781C11.561 14.702 11.2995 14.9634 10.9756 14.9634H7.46342C7.13952 14.9634 6.87805 14.702 6.87805 14.3781C6.87805 14.0542 7.13952 13.7927 7.46342 13.7927Z"
										fill="white"
									/>
								</svg>
							</span>

							Read Jack's story here
						</NuxtLink>
					</div>
				</div>
				<div class="student-case-study">
					<img
						src="/images/grittani2.webp"
						width="124"
						height="136"
						loading="lazy"
						alt="Grittani Case Study Pic"
					/>

					<div class="flex flex-col gap-5">
						<Paragraph>
							<b>Tim Grittani.</b>
							Tim became my most profitable student ever by mastering short selling.
						</Paragraph>

						<NuxtLink
							to="/blog/case-study-tim-grittani/"
							class="case-study-link"
						>
							<span class="case-study-link-icon">
								<svg
									width="20"
									height="19"
									viewBox="0 0 20 19"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.5122 1.69513C4.5444 1.69513 3.7561 2.48342 3.7561 3.45123V15.5488C3.7561 16.5166 4.5444 17.3049 5.5122 17.3049H14.4878C15.4556 17.3049 16.2439 16.5166 16.2439 15.5488V7.15854H12.5366C11.5688 7.15854 10.7805 6.37025 10.7805 5.40245V1.69513H5.5122ZM11.9512 1.73019V5.40245C11.9512 5.72635 12.2127 5.98781 12.5366 5.98781H16.2088C16.1815 5.90586 16.1349 5.8315 16.0724 5.76906L12.17 1.86662C12.1075 1.80418 12.0332 1.75751 11.9512 1.73019ZM7.46342 9.10976H12.5366C12.8605 9.10976 13.122 9.37123 13.122 9.69513C13.122 10.019 12.8605 10.2805 12.5366 10.2805H7.46342C7.13952 10.2805 6.87805 10.019 6.87805 9.69513C6.87805 9.37123 7.13952 9.10976 7.46342 9.10976ZM7.46342 11.4512H12.5366C12.8605 11.4512 13.122 11.7127 13.122 12.0366C13.122 12.3605 12.8605 12.622 12.5366 12.622H7.46342C7.13952 12.622 6.87805 12.3605 6.87805 12.0366C6.87805 11.7127 7.13952 11.4512 7.46342 11.4512ZM7.46342 13.7927H10.9756C11.2995 13.7927 11.561 14.0542 11.561 14.3781C11.561 14.702 11.2995 14.9634 10.9756 14.9634H7.46342C7.13952 14.9634 6.87805 14.702 6.87805 14.3781C6.87805 14.0542 7.13952 13.7927 7.46342 13.7927Z"
										fill="white"
									/>
								</svg>
							</span>

							Read Tim's story here
						</NuxtLink>
					</div>
				</div>
				<div class="student-case-study">
					<img
						src="/images/croock2.webp"
						loading="lazy"
						alt="Croock Case Study Pic"
					/>

					<div class="flex flex-col gap-5">
						<Paragraph>
							<b>Mark Croock.</b>
							Mark adapted my penny stock strategies for options trading.
						</Paragraph>

						<NuxtLink
							to="/blog/evolution-of-mark-croock/"
							class="case-study-link"
						>
							<span class="case-study-link-icon">
								<svg
									width="20"
									height="19"
									viewBox="0 0 20 19"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.5122 1.69513C4.5444 1.69513 3.7561 2.48342 3.7561 3.45123V15.5488C3.7561 16.5166 4.5444 17.3049 5.5122 17.3049H14.4878C15.4556 17.3049 16.2439 16.5166 16.2439 15.5488V7.15854H12.5366C11.5688 7.15854 10.7805 6.37025 10.7805 5.40245V1.69513H5.5122ZM11.9512 1.73019V5.40245C11.9512 5.72635 12.2127 5.98781 12.5366 5.98781H16.2088C16.1815 5.90586 16.1349 5.8315 16.0724 5.76906L12.17 1.86662C12.1075 1.80418 12.0332 1.75751 11.9512 1.73019ZM7.46342 9.10976H12.5366C12.8605 9.10976 13.122 9.37123 13.122 9.69513C13.122 10.019 12.8605 10.2805 12.5366 10.2805H7.46342C7.13952 10.2805 6.87805 10.019 6.87805 9.69513C6.87805 9.37123 7.13952 9.10976 7.46342 9.10976ZM7.46342 11.4512H12.5366C12.8605 11.4512 13.122 11.7127 13.122 12.0366C13.122 12.3605 12.8605 12.622 12.5366 12.622H7.46342C7.13952 12.622 6.87805 12.3605 6.87805 12.0366C6.87805 11.7127 7.13952 11.4512 7.46342 11.4512ZM7.46342 13.7927H10.9756C11.2995 13.7927 11.561 14.0542 11.561 14.3781C11.561 14.702 11.2995 14.9634 10.9756 14.9634H7.46342C7.13952 14.9634 6.87805 14.702 6.87805 14.3781C6.87805 14.0542 7.13952 13.7927 7.46342 13.7927Z"
										fill="white"
									/>
								</svg>
							</span>

							Read Mark's story here
						</NuxtLink>
					</div>
				</div>
			</div>
		</Container>
	</Row>

	<TradingGuidesList />

	<TrustPilotSection />
	<JoinWebinarSection />

	<Row class="apply-challenge-section">
		<Container class="xl:grid grid-cols-2">
			<div class="mb-[32px] lg:mb-0">
				<Heading
					tag="h2"
					type="big"
					class="text-white mb-[16px] lg:mb-[24px]"
					text="Apply to Join My Trading Challenge"
				/>

				<Paragraph class="text-white mb-[16px] lg:mb-[24px]">
					We only take students willing to study hard and be a contributing member to
					our great community. Think you've got what it takes?
				</Paragraph>
				<Paragraph class="text-white mb-[16px] lg:mb-[24px]">
					This is where every one of 30+ millionaire students started...
				</Paragraph>

				<JoinPitch
					:is-white="true"
					class="mb-[16px] lg:mb-[24px]"
				/>

				<CtaButton
					to="/millionaire-challenge/"
					dataCtaType="join-challenge"
				>
					Apply to Join the challenge
				</CtaButton>
			</div>
			<div>
				<img
					src="/images/trading-challenge-image.webp"
					width="600"
					height="392"
					loading="lazy"
					alt="trading-challenge-image"
					class="block mt-[32px] xl:mt-0 mx-auto xl:ml-auto"
				/>
			</div>
		</Container>
	</Row>

	<SykesOnYoutube />
	<SykesOnX />

	<Row class="apply-challenge-section pb-0">
		<Container class="xl:grid grid-cols-2">
			<div class="mb-[32px] lg:mb-0">
				<Heading
					tag="h2"
					type="big"
					class="text-white mb-[16px] lg:mb-[24px]"
					text="Ready to take action?"
				/>

				<Paragraph class="text-white mb-[16px] lg:mb-[24px]">
					Sign up to our millionaire traders' FREE watchlist and start seeing the
					opportunities they see every day!
				</Paragraph>

				<JoinPitch
					:is-white="true"
					class="mb-[16px] lg:mb-[24px]"
				/>
				<LeadCaptureForm id="apply-challende-form" />
			</div>
			<div>
				<img
					src="/images/assets/sykes-cta-1.webp"
					width="600"
					height="447"
					loading="lazy"
					class="ready-to-take-action-1"
					alt="sykes-cta-1 image"
				/>
				<img
					src="/images/assets/sykes-cta-2.webp"
					width="393"
					height="621"
					loading="lazy"
					alt="sykes-cta-2 image"
					class="ready-to-take-action-2"
				/>
			</div>
		</Container>
	</Row>

	<Head>
		<Title>Timothy Sykes - Millionaire Penny Stock Trader</Title>
		<Link
			rel="canonical"
			:href="$config.public.current_hostname"
		/>
		<Meta
			name="description"
			content="Timothy Sykes is a millionaire penny stock trader and entrepreneur. He is best known for earning $1.65 million by day trading while attending Tulane University."
		/>
		<Meta
			content="en_US"
			property="og:locale"
		/>
		<Meta
			content="article"
			property="og:type"
		/>
		<Meta
			content="Timothy Sykes : Millionaire Penny Stock Trader"
			property="og:title"
		/>
		<Meta
			content="Timothy Sykes is a millionaire penny stock trader and entrepreneur. 
He is best known for earning $1.65 million by day trading while attending Tulane University."
			property="og:description"
		/>
		<Meta
			:content="$config.public.current_hostname"
			property="og:url"
		/>
		<Meta
			content="Timothy Sykes"
			property="og:site_name"
		/>
		<Meta
			content="2022-04-18T07:17:16+00:00"
			property="article:modified_time"
		/>
		<Meta
			content="https://content.timothysykes.com/wp-content/uploads/2019/06/Conclusion-How-to-Read-Stock-Charts.jpg"
			property="og:image"
		/>
		<Meta
			name="twitter:card"
			content="summary_large_image"
		/>
		<Meta
			name="twitter:site"
			content="@timothysykes"
		/>
	</Head>
</template>

<script setup>
import HomeNewsSection from "../components/homepage/HomeNewsSection.vue";

const $config = useRuntimeConfig();
const schema = {
	"@context": "https://schema.org",
	"@type": "Organization",
	name: "Timothy Sykes",
	url: "https://www.timothysykes.com",
	description: "How To Trade Penny Stocks Without The Stock Promoter BS",
	disambiguatingDescription:
		"A leading provider of investment education and resources, founded in 2005.",
	foundingDate: "2005-01-15", // Boilerplate text
	logo: {
		"@type": "ImageObject",
		url: $config.public.hostname + "images/ts-logo-icon.png",
		width: 512,
		height: 512
	},
	image: {
		"@type": "ImageObject",
		url: $config.public.hostname + "images/ts-logo-white-bg.png",
		width: 503,
		height: 95
	},
	sameAs: [
		"https://en.wikipedia.org/wiki/Timothy_Sykes",
		"https://www.youtube.com/@TimothySykesTrader",
		"https://www.facebook.com/timsykesfans/",
		"https://twitter.com/timothysykes",
		"https://www.linkedin.com/in/timothysykes/",
		"https://www.instagram.com/timothysykes/",
		"https://www.tiktok.com/@timothysykes",
		"https://profit.ly/user/timothysykes"
	],
	contactPoint: [
		{
			"@type": "ContactPoint",
			contactType: "customer service",
			telephone: "+1-888-878-3621",
			email: "support-alerts@timothysykes.com",
			areaServed: "US",
			availableLanguage: ["English", "Spanish"]
		},
		{
			"@type": "ContactPoint",
			contactType: "media inquiries",
			telephone: "+1-800-878-3621",
			email: "press@timothysykes.com",
			areaServed: "US",
			availableLanguage: ["English"]
		}
	],
	address: {
		"@type": "PostalAddress",
		streetAddress: "66 W. Flagler St. - Suite 900",
		addressLocality: "Miami",
		addressRegion: "FL",
		postalCode: "33130",
		addressCountry: "US"
	},
	founder: {
		"@type": "Person",
		name: "Timothy Sykes",
		url: "https://www.timothysykes.com/blog/author/tim-sykes/",
		sameAs: [
			"https://www.youtube.com/@TimothySykesTrader",
			"https://en.wikipedia.org/wiki/Timothy_Sykes",
			"https://profit.ly/user/timothysykes",
			"https://www.facebook.com/timsykesfans/",
			"https://twitter.com/timothysykes",
			"https://www.linkedin.com/in/timothysykes/",
			"https://www.instagram.com/timothysykes/",
			"https://www.tiktok.com/@timothysykes",
			"https://www.imdb.com/name/nm2418506/",
			"https://observer.com/author/timothy-sykes/",
			"https://books.apple.com/us/author/timothy-sykes/id281184034",
			"https://www.huffpost.com/author/tim-501",
			"https://fortune.com/author/timothy-sykes/",
			"https://www.entrepreneur.com/author/timothy-sykes",
			"https://www.goodreads.com/author/list/748145.Timothy_Sykes",
			"https://www.amazon.com/stores/author/B002BLQ4YC",
			"https://www.businessinsider.com/author/timothy-sykes",
			"https://www.theguardian.com/profile/timothy-sykes"
		],
		image: [
			{
				"@type": "ImageObject",
				url: "https://content.timothysykes.com/wp-content/uploads/2024/08/timsykes.webp",
				width: 306,
				height: 384
			},
			{
				"@type": "ImageObject",
				url: "https://secure.gravatar.com/avatar/092522e87ab76a4bb688d6bfdc0b4f2e?s=96&d=mm&r=g",
				height: 96,
				width: 96
			}
		]
	},
	award: ["Timothy Sykes Day Trading Award for the Talented"],
	publishingPrinciples: "https://www.timothysykes.com/editorial-guidelines/"
};
useHead(() => {
	return {
		meta: [
			{
				hid: "trustpilot-title",
				name: "trustpilot-title",
				content: "Trustpilot Reviews - Timothy Sykes"
			},
			{
				hid: "trustpilot-description",
				name: "trustpilot-description",
				content: "Read reviews from our satisfied customers on Trustpilot."
			}
		],
		script: [
			{
				async: true,
				type: "text/javascript",
				src: "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
			},
			{
				type: "application/ld+json",
				class: "saswp-schema-markup-output",
				innerHTML: JSON.stringify(schema)
			},
			{
				type: "application/ld+json",
				class: "saswp-schema-markup-output",
				innerHTML: JSON.stringify({
					"@context": "https://schema.org",
					"@type": "WebSite",
					name: "Timothy Sykes",
					url: "https://www.timothysykes.com/",
					potentialAction: {
						"@type": "SearchAction",
						target: {
							"@type": "EntryPoint",
							urlTemplate:
								"https://www.timothysykes.com/search/?q={search_term_string}"
						},
						"query-input": {
							"@type": "PropertyValueSpecification",
							valueRequired: "http://schema.org/True",
							valueName: "search_term_string"
						}
					}
				})
			}
		]
	};
});
</script>

<style lang="scss" scoped>
@import "../assets/utils.scss";

.apply-challenge-section {
	background-color: $primary-900;
	background-image: url("/images/assets/texture.svg");
	background-position: center;

	&.pb-0 {
		padding-bottom: 0;
	}
}

.case-study-section {
	background: #142960;
	background-image: url("/images/assets/texture-2.svg");
	background-position: center;
	background-repeat: repeat;
	background-size: contain;
	h2.subtitle {
		color: white;
		margin-bottom: 24px;

		@include breakpoint(lg) {
			margin-bottom: 56px;
		}
	}
}

.student-case-study {
	display: flex;
	padding: 24px;
	border-radius: 8px;
	background-color: white;
	gap: 16px;
	flex-direction: column;
	align-items: center;
	text-align: center;

	p {
		font-size: 20px;

		@include breakpoint(lg) {
			font-size: 24px;
		}
	}

	img {
		height: 136px;
		width: 136px;
		border-radius: 8px;
	}

	@include breakpoint(lg) {
		flex-direction: row;
		text-align: left;
	}
}

.case-study-link {
	display: inline-flex;
	align-items: center;
	gap: 8px;
	color: #eb5528;
	font-family: Fira Sans;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 25px;
	text-align: center;
	justify-content: center;

	@include breakpoint(lg) {
		text-align: left;
		justify-content: flex-start;
	}

	/* 138.889% */
	&-icon {
		height: 32px;
		width: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 16px;
		background-color: #eb5528;
	}
}

.ts-feature-block {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 24px 16px;
	background-color: white;
	border-radius: 8px;
	align-items: center;
	text-align: center;
	position: relative;

	@include breakpoint(xl) {
		padding: 24px 8px;

		&:nth-child(1) {
			left: 40px;
		}

		&:nth-child(2) {
			left: 40px;
		}
	}

	img {
		max-width: 80px;
	}
}

.home-collage-1 {
	position: relative;
	height: 340px;
	max-width: 280px;
	width: 280px;

	@include breakpoint(md) {
		height: 376px;
		max-width: 370px;
		width: 370px;
	}
	@include breakpoint(lg) {
		height: 540px;
		max-width: unset;
		width: unset;
	}

	img {
		border-radius: 8px;
		box-sizing: content-box;
		border-radius: 8px;
		outline-offset: -4px;
		outline: 8px solid black;
	}

	img:nth-child(1) {
		position: absolute;
		left: -220px;
		z-index: 1;
		margin: auto;
		top: 120px;
	}

	img:nth-child(2) {
		position: absolute;
		right: 0;
		z-index: 1;
		margin: auto;
		top: 0;
	}
}

.trading-benefits {
	padding-top: 24px;
	padding-bottom: 24px;
	outline: 4px solid #eff6ff;
	border-radius: 12px;
	margin: 0 12px;
	max-width: 400px;
	@include breakpoint(lg) {
		padding-top: 0;
		padding-bottom: 0;
		outline: unset;
		margin: auto;
	}
}

.home-collage {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	margin-top: 64px;

	@include breakpoint(lg) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 20px;
		margin-top: 86px;
	}
}

.ready-to-take-action-1 {
	display: none;
	margin-left: auto;
	margin-right: auto;

	@include breakpoint(lg) {
		display: block;
	}
	@include breakpoint(xl) {
		margin-left: auto;
	}
}

.ready-to-take-action-2 {
	display: block;
	margin-left: auto;
	margin-right: auto;
	@include breakpoint(lg) {
		display: none;
	}
}
</style>

<template>
	<Row class="sykes-on-x">
		<Container class="lg:grid grid-cols-2 gap-[32px] items-center">
			<div class="w-full mb-[32px]">
				<div class="flex gap-[32px] items-center mb-[24px]">
					<img
						src="/images/assets/profile.webp"
						width="158"
						height="158"
						alt=""
					/>

					<div class="text-white">
						<p class="text-base font-semibold">Timothy Sykes</p>
						<p class="text-base">@timothysykes</p>
					</div>
				</div>
				<Paragraph class="text-white">
					I've turned $12k into $7.9 million trading stocks from 132
					countries/building 115 schools, go apply at
					<NuxtLink
						class="underline goldenLink"
						href="http://tim.ly/sykeschallenge"
					>
						tim.ly/sykeschallenge
					</NuxtLink>
					to be my dedicated student!
				</Paragraph>
			</div>
			<div class="w-full">
				<Heading
					tag="h2"
					type="big"
					class="border-l-[8px] pl-[16px] text-white border-primary-500 leading-none mb-[24px]"
					text="Timothy Sykes on Twitter"
				/>

				<div class="stats-container mb-[48px]">
					<div class="stat-item">
						<span
							class="icon"
							style="background-color: #eab308"
						>
							<svg
								width="14"
								height="17"
								viewBox="0 0 14 17"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									id="Vector"
									d="M5.79998 0.5V2.25625C3.73052 2.78968 2.19998 4.66384 2.19998 6.9V11.7L0.599976 13.3V14.1H13.4V13.3L11.8 11.7V6.9C11.8 4.66384 10.2694 2.78968 8.19998 2.25625V0.5H5.79998ZM5.39998 14.9C5.39998 15.78 6.11998 16.5 6.99998 16.5C7.87998 16.5 8.59998 15.78 8.59998 14.9H5.39998Z"
									fill="white"
								/>
							</svg>
						</span>

						<span class="highlight">311.3K</span>

						subscribers
					</div>
				</div>

				<CtaButton
					to="https://twitter.com/timothysykes"
					:blank="true"
					type="secondary"
					class="alignButtonMobile"
				>
					Follow Timothy Sykes on Twitter
				</CtaButton>
			</div>
		</Container>
	</Row>
</template>

<style lang="scss" scoped>
@import "../assets/utils.scss";
.sykes-on-x {
	background-color: #112357;
	background-image: url("../../images/assets/x-bg.svg");
	background-repeat: no-repeat;
}

.youtube-thumbnails {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, 1fr);
	gap: 16px;
	align-items: center;
	a {
		width: 100%;
		img {
			width: 100%;
		}
	}
}

.youtube-item:first-child {
	grid-column: span 2;
	grid-row: span 2;
}

.stats-container {
	display: flex;
	justify-content: flex-start;
	gap: 32px;
}

.stat-item {
	display: flex;
	align-items: flex-end;
	gap: 8px;
	color: #fff;

	/* Body/Xlarge */
	font-family: Rubik;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	white-space: nowrap;
}

.stat-item .icon {
	border-radius: 32px;
	justify-content: center;
	align-items: center;
	display: flex;
	height: 40px;
	width: 40px;
}

.stat-item .highlight {
	font-family: Fira Sans;
	font-size: 38px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
	/* 45.6px */
	letter-spacing: -0.76px;
}

.goldenLink {
	color: #eab308;
}

.alignButtonMobile {
	text-align: center;
	@include breakpoint(md) {
		text-align: left;
	}
}
</style>
